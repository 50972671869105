import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import axios from "axios"

Vue.config.productionTip = false

Vue.use(Antd)

const service = axios.create({
  // baseURL: 'https://xingqiu.uat.automark.cc',
  baseURL: 'https://go.matrixgorilla.com',
  timeout: 5000000
})

Vue.prototype.$axios = service;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

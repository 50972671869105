import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: "/",
    redirect: "/nomenu/overview"
  },
  {
    path: "/nomenu/",
    name: 'NoMenu',
    component: () => import("../components/NoMenu.vue"),
    children: [
      {
        path: "overview",
        name: 'Overview',
        component: () => import("../views/nomenu/Overview.vue"),
      },
      {
        path: "test",
        name: 'Test',
        component: () => import("../views/nomenu/Test.vue"),
      },
      // ...
    ]
  },
  {
    path: "/menu/",
    name: 'Menu',
    component: () => import("../components/Menu.vue"),
    children: [
      {
        path: "recommendation",
        name: 'Recommendation',
        component: () => import("../views/menu/Recommendation/Index.vue"),
      },
      {
        path: "satisfaction",
        name: 'Satisfaction',
        component: () => import("../views/menu/Satisfaction"),
      },
      {
        path: "surprise",
        name: 'Surprise',
        component: () => import("../views/menu/Surprise"),
      },
      // ...
    ]
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

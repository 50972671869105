<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import locale from 'ant-design-vue/es/locale/zh_CN';

export default {
  name: 'App',
  data() {
    return {
      locale
    }
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #fff;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.1);
    border-radius: 8px;
    cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
    border-radius: 8px;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background-color: #fff;
}
</style>
